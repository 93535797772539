<template>
	<div class="map">
		<el-main style="width: 100%;height: 92vh;">
			<div id="ProvinceData-map2" style="width:100%;height:100%;"></div>
		</el-main>
	</div>
</template>

<script>
// import location from '@/assets/positionLocation.js'

// let location = {
// 	initMap(id) {
// 		let mapObj = new AMap.Map(id, {})
// 		let geolocation;
// 		mapObj.plugin(['AMap.Geolocation'], function () {
// 			geolocation = new AMap.Geolocation({
// 				enableHighAccuracy: true, //  是否使用高精度定位，默认:true
// 				timeout: 10000, //  超过10秒后停止定位，默认：无穷大
// 				maximumAge: 0, // 定位结果缓存0毫秒，默认：0
// 				convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
// 				showButton: true, //  显示定位按钮，默认：true
// 				buttonPosition: 'LB', // 定位按钮停靠位置，默认：'LB'，左下角
// 				buttonOffset: new AMap.Pixel(10, 20), //  定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
// 				showMarker: true, //  定位成功后在定位到的位置显示点标记，默认：true
// 				showCircle: true, //  定位成功后用圆圈表示定位精度范围，默认：true
// 				panToLocation: true, //  定位成功后将定位到的位置作为地图中心点，默认：true
// 				zoomToAccuracy: true //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
// 			})
// 			mapObj.addControl(geolocation)
// 			geolocation.getCurrentPosition()
// 		})
// 		return geolocation;
// 	}
// }
let tableData = [
	
	{
		longitude: '123.37384',//经度
		latitude: '41.81046',//纬度
		name: '李巧妍',
		address: '辽宁省沈阳市铁西区兴顺街3号',
		phone: '15601200801'
	},
	{
		longitude: '106.30046',//经度
		latitude: '38.45889',//纬度
		name: '张海玲',
		address: '宁夏回族自治区 银川市 兴庆区 银古路街道',
		phone: ''
	},
	{
		longitude: '114.09396',//经度
		latitude: '22.54741',//纬度
		name: '栾岩',
		address: '广东省深圳市福田区华强北路赛格电子大厦3B01',
		phone: ''
	},
	{
		longitude: '121.44782',//经度
		latitude: '31.30227',//纬度
		name: '大总管总部',
		address: '上海市静安区中环中铁时代广场4号楼501室',
		phone: ''
	},
]
export default {
	data() {
		return {
			MAps: null,
		}
	},
	methods: {
		
		initMaps() {
			// 配置地图的基本显示
			let that = this;
			that.MAps = new AMap.Map("ProvinceData-map2", {
				resizeEnable: true, //是否监控地图容器尺寸变化
				viewMode: '3D', //开启3D视图,默认为关闭
				buildingAnimation: true, //楼块出现是否带动画
				zoom: 5, //初始化地图层级
				center: [106.04124, 35.20204], //初始化地图中心点
				pitch: 15,
				rotation: 0,
				pitchEnable: true,
				jogEnable: true,//地图是否使用缓动效果
				zooms: [2, 20],
				showIndoorMap: false,
			});
			
			AMap.plugin([ //添加插件
				'AMap.ControlBar',
			], function () {
				// 添加 3D 罗盘控制
				that.MAps.addControl(new AMap.ControlBar({
					showZoomBar: false,
					showControlButton: true,
					position: {
						right: '10px',
						top: '10px'
					}
				}));
			},);
			
			
			AMap.plugin(["AMap.ToolBar"], function () { //加载工具条
				var tool = new AMap.ToolBar();
				that.MAps.addControl(tool);
			});
			
			var infoWindow = new AMap.InfoWindow({
				offset: new AMap.Pixel(0, -30)
			});
			
			for (let i = 0; i < tableData.length; i++) {
				
				
				var marker = new AMap.Marker({
					title: '代理商',
					position: new AMap.LngLat(tableData[i].longitude, tableData[i].latitude),
					icon: new AMap.Icon({
						image: 'https://dgjoa.oss-cn-shanghai.aliyuncs.com/publicFile/016-%E4%BB%A3%E7%90%86%E5%95%86.png',
						size: new AMap.Size(152, 152), //图标大小
						imageSize: new AMap.Size(46, 46)
					}),
					iconStyle: {
						src: '//webapi.amap.com/theme/v1.3/markers/b/mark_r.png',
						style: {
							width: '20px',
							height: '30px',
							color: "#FFF"
						}
					},
					offset: new AMap.Pixel(-13, -30),
				});
				
				AMap.event.addListener(marker, 'click', function () {
					infoWindow.open(that.MAps, marker.getPosition());
				});
				// 实例化信息窗体
				var content = [
					`<span style='font-size:14px;color:#00aaff;width:320px'> ${tableData[i].name}</span>`,
					`<span style='font-size:11px;color:#8a8a8a;width:320px'>地址: ${tableData[i].address}</span>`,
				];
				marker.content = content.join("<br/>");
				marker.on('click', markerClick);
				marker.emit('click', {
					target: marker
				});
				that.MAps.add(marker);
			}
			
			
			function markerClick(e) {
				infoWindow.setContent(e.target.content);
				infoWindow.open(that.MAps, e.target.getPosition());
			}
			
			that.MAps.setFitView();
			
			
			// let geolocation = location.initMap("map-container"); //定位
			// AMap.event.addListener(geolocation, "complete", result => {
			// 	console.log(result)
			// });
			
			
			// 点击选中搜索出来的信息时触发
			
			that.MAps.on('click', (ev) => {
				console.log(ev)
			})
		},
		
		
	},
	mounted() {
		this.initMaps()
		
	},
	
}
</script>

<style scoped>
>>> .amap-logo {
	display: none !important;
	opacity: 0 !important;
}

>>> .amap-copyright {
	opacity: 0;
}

.text {
	font-size: 14px;
}

.item {
	padding: 18px 0;
	
}

.box-card {
	width: 480px;
}

.hidden {
	display: none;
}

.map .el-main {
	background-color: #ffffff;
}

.map .el-aside {
	background-color: #ffffff;
}

.tab-content-table-pagination {
	margin-top: 10px;
	text-align: right;
}

.hidden {
	display: none;
}
</style>
<style>
.content-window-card {
	position: relative;
	box-shadow: none;
	bottom: 0;
	left: 0;
	width: auto;
	padding: 0;
}

.content-window-card p {
	height: 2rem;
}

.custom-info {
	border: solid 1px silver;
}

div.info-top {
	position: relative;
	background: none repeat scroll 0 0 #F9F9F9;
	border-bottom: 1px solid #CCC;
	border-radius: 5px 5px 0 0;
}

div.info-top div {
	display: inline-block;
	color: #333333;
	font-size: 14px;
	font-weight: bold;
	line-height: 31px;
	padding: 0 10px;
}

div.info-top img {
	position: absolute;
	top: 10px;
	right: 10px;
	transition-duration: 0.25s;
}

div.info-top img:hover {
	box-shadow: 0px 0px 5px #000;
}

div.info-middle {
	font-size: 12px;
	padding: 10px 6px;
	line-height: 20px;
}

div.info-bottom {
	height: 0px;
	width: 100%;
	clear: both;
	text-align: center;
}

div.info-bottom img {
	position: relative;
	z-index: 104;
}

span {
	margin-left: 5px;
	font-size: 11px;
}

.info-middle img {
	float: left;
	margin-right: 6px;
}
</style>
